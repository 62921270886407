import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { ScrollTop } from 'icons'
import { Container, Button } from './buttonTop.styles'

export default function ButtonTop() {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery(
    { maxDeviceWidth: 1024 },
    { deviceWidth: 1024 },
  )

  if (!isMobileOrTablet) return <Container />
  return (
    <Container>
      <Button
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
        aria-label={t('commons:buttonTop:label')}
      >
        <ScrollTop />
      </Button>
    </Container>
  )
}
