import { styled, ButtonPrimaryTextOnly } from '@creditas-ui/react'

export const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 80px;
`

export const Button = styled(ButtonPrimaryTextOnly)`
  border-radius: 100%;
  width: 40px;
  height: 40px;

  svg {
    margin-top: 5px;
  }
`
